<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <h2 class="mt-5 text-colored text-uppercase">{{ title }}</h2>
  <hr class="hr-colored col-2 mx-auto mb-5" />
  <v-row>
    <v-col
      v-for="product in products"
      :key="product.id"
      class="pb-3"
      :lg="products.length < 4 ? 4 : 3"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <v-card flat color="transparent">
        <v-card-title class="mb-2">
          <v-img
            :src="product.productMainImage"
            contain
            class="mb-2 align-end product-img"
            height="400"
          ></v-img>
        </v-card-title>

        <v-card-text>
          <div>
            <router-link
              :to="'/product/details/' + product.slug"
              class="nav-link text-colored"
            >
              <h5 class="float-start">{{ product.productName }}</h5>
            </router-link>
            <h5
              class="ml-auto text-colored"
              :class="[
                { 'text-left': $vuetify.locale.current == 'ar' },
                { 'text-right': $vuetify.locale.current == 'en' },
              ]"
            >
              {{ product.price }}LYD
            </h5>
          </div>
          <div>
            <div class="row">
              <h5>
                <a
                  :href="'/product/shop/' + product.companySlug"
                  class="text-colored"
                >
                  <i
                    :class="[
                      { 'float-start': $vuetify.locale.current == 'en' },
                      { 'float-end': $vuetify.locale.current == 'ar' },
                    ]"
                    >{{ product.companyName }}</i
                  >
                </a>
              </h5>

              <h6 class="d-block">
                <i
                  :class="[
                    { 'float-start': $vuetify.locale.current == 'en' },
                    { 'float-end': $vuetify.locale.current == 'ar' },
                  ]"
                  >{{ product.category }}</i
                >
              </h6>
            </div>

            <v-btn
              @click="addToCart(product)"
              flat
              class="ml-auto text-white bg-colored"
              :class="[
                { 'float-start': $vuetify.locale.current == 'ar' },
                { 'float-end': $vuetify.locale.current == 'en' },
              ]"
            >
              <font-awesome-icon icon="fas fa-cart-shopping" />
              {{ t("$vuetify.cart.add") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    products: Array,
  },
  methods: {
    addToCart(card) {
      this.$store.commit("addToCart", card);
    },
    removeFromCart(card) {
      this.$store.commit("removeFromCart", card);
    },
  },
};
</script>
