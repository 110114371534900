import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/product",
    children: [
      {
        path: "shop",
        name: "product-list",
        component: () => import("../views/ListProductView.vue"),
      },
      {
        path: "shop/:company",
        name: "product-list-company",
        component: () => import("../views/ListProductCompanyView.vue"),
      },
      {
        path: "details/:id",
        name: "product-details",
        component: () => import("../views/ProductView.vue"),
      },
    ],
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/AboutView.vue"),
  },

  {
    path: "/auth",
    beforeEnter: () => {
      if (localStorage.getItem("_token") != null) {
        router.push("/");
        return;
      }
    },
    children: [
      {
        path: "signin",
        name: "signin",
        component: () => import("../views/SignInView.vue"),
      },
      {
        path: "signup",
        name: "signup",
        component: () => import("../views/SignUpView.vue"),
      },
      {
        path: "resetpassword",
        name: "forget.password",
        component: () => import("../components/Auth/ForgetPassword.vue"),
      },
      {
        path: "confirmpassword/:uid/:token",
        name: "auth.forget",
        component: () => import("@/components/Auth/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/components/CheckOut.vue"),
    beforeEnter: () => {
      if (localStorage.getItem("_token") == null) {
        router.push("/auth/signin");
        return;
      }
    },
  },
  {
    path: "/success",
    name: "payment",
    component: () => import("@/components/PaymentSuccess.vue"),
  },
  {
    path: "/clientarea",
    name: "client.area",
    beforeEnter: () => {
      if (localStorage.getItem("_token") == null) {
        router.push("/");
        return;
      }
    },
    component: () => import("@/components/ClientArea.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
